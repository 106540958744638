// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'name'
  }, {
    prop: 'name_en'
  }, {
    prop: 'postal_code_range'
  }]
}
